"use client"

import styled from "@emotion/styled"
import { NumbersComponent } from "ui"

const StyledDiv = styled.div``

const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

type Props = {
  data: any
  backgroundColor?: string
  textColor?: string
}

const NumbersWrapper: React.FC<Props> = ({ data, backgroundColor, textColor }) => {
  return (
    <StyledDiv>
      <StyledWrapper style={{ backgroundColor: backgroundColor }}>
        <NumbersComponent
          data={data}
          backgroundColor={backgroundColor}
          textColor={textColor}
          accentColor={textColor}
          titleColor={textColor}
        />
      </StyledWrapper>
    </StyledDiv>
  )
}
export default NumbersWrapper

"use client"

import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import Link from "next/link"
import { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { Container, LeftArrow, NewText, useInViewConfig } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { activeArmsClick, extractImage, extractProperty } from "utils"

const StyledContainer = styled.div`
  background: ${(props) => props.theme.colors.propBlue};
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;
  height: auto;
`

const StyledDescriptionContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 0px;
  p {
    margin: 0px;
  }
`

const StyledMainContainer = styled.div<{
  $animateFirst: boolean
  $animateSecond: boolean
  $animateFour: boolean
  $height: number
}>`
  position: relative;
  min-height: 600px;
  overflow: hidden;

  height: ${(p) => (p.$animateFour ? `${p.$height}px` : "auto")};
  a {
    width: ${(p) => (p.$animateFour ? "100%" : "200px")};
    height: ${(p) => (p.$animateFour ? "165px" : "200px")};
    display: block;
  }

  transition: ${(p) => !p.$animateFour && "transform 600ms ease-in-out, all 600ms ease-in-out"};
`

const StyledCard = styled.div<{
  $animateSecond: boolean
  $animateFirst: boolean
  $animateFour: boolean
}>`
  position: absolute;
  width: ${(p) => (p.$animateFour ? "calc(50% - 8px)" : "200px")};
  height: ${(p) => (p.$animateFour ? "165px" : "200px")};
  border-radius: 12px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);

  transform: ${(p) =>
    p.$animateFirst
      ? "rotate(-30deg) skew(30deg) scale(0.8)"
      : p.$animateFour
      ? "rotate(0deg) skew(0deg) scale(1)"
      : "rotate(-30deg) skew(30deg) scale(0.8)"};
  transition: ${(p) =>
    !p.$animateFour &&
    "transform 600ms ease-in-out, all 600ms ease-in-out, opacity 400ms ease-in-out"};
  overflow: hidden;
`

const StyledOverlay = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 1;
  border-radius: 12px;
`

const StyledImageWrapp = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 12px;
`

const StyledLogoDiv = styled.div``

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  align-items: flex-end;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  svg {
    path {
      fill: white;
    }
  }
  button {
    height: 48px;
  }
`

const StyledWrapper = styled.div<{
  $animateFirst: boolean
  $animateSecond: boolean
  $animateFour: boolean
  $length: number
}>`
  ${(p) =>
    p.$animateFirst &&
    Array.from(
      { length: p.$length },
      (_, i) => `
    & > div:nth-child(${i + 1}) {
      top: calc(50% - ${210 - 30 * i}px);
      left: calc(50% - 100px);
    }
  `,
    ).join("")}

  ${(p) =>
    p.$animateSecond &&
    Array.from(
      { length: p.$length },
      (_, i) => `
    & > div:nth-child(${i + 1}) {
      top: calc(50% - ${210 - 20 * i}px);
      left: calc(50% - 100px);
    }
  `,
    ).join("")}


${(p) =>
    p.$animateFour &&
    Array.from({ length: 8 }, (_, i) => {
      const row = Math.floor(i / 2)
      const column = i % 2
      const top = 24 + row * 189
      const left = column * 50
      const delay = 200 + row * 150
      return `
      & > div:nth-child(${i + 1}) {
        top: ${top}px;
        left: ${left}%;
        animation: slide-in ${delay}ms forwards;
        transition: transform ${delay}ms ease-in-out, opacity ${delay}ms ease-in-out;
      }
    `
    }).join("")}
  @keyframes slide-in {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`

type Props = {
  data: any
}

const AnimateCardsMobile = ({ data }: Props) => {
  const title = extractProperty(data, "title")
  const text = extractProperty(data, "text")
  const arms = extractProperty(data, "arms")

  const armsList = arms.map((prop: any) => {
    return {
      logoImage: extractImage(prop, "logoImage"),
      armsText: extractProperty(prop, "armsText"),
      armLink: extractProperty(prop, "armLink"),
      image: extractImage(prop, "image"),
    }
  })

  const [animateFirst, setAnimateFirst] = useState(true)
  const [animateSecond, setAnimateSecond] = useState(false)

  const [animateFour, setAnimateFour] = useState(false)

  const { ref, inView } = useInView(useInViewConfig)

  const cardHeight = 165
  const cardSpace = 24

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setAnimateFirst(false)
        setAnimateSecond(true)
        setTimeout(() => {
          setAnimateSecond(false)
          setAnimateFour(true)
        }, 800)
      }, 200)
    }
  }, [inView])

  return (
    <StyledContainer ref={ref}>
      <Container>
        <NewText
          desktopFontSize={32}
          desktopFontWeight="400"
          desktopLineHeight="110%"
          component="h2"
          color={"white"}
        >
          {title}
        </NewText>

        <StyledDescriptionContainer>
          <NewText
            desktopFontSize={18}
            desktopFontWeight="400"
            desktopLineHeight="26px"
            mobileFontSize={16}
            mobileFontWeight="400"
            mobileLineHeight={"23px"}
            component="div"
            color={"white"}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </StyledDescriptionContainer>
        <StyledMainContainer
          $animateFirst={animateFirst}
          $animateSecond={animateSecond}
          $animateFour={animateFour}
          $height={cardHeight * (armsList.length / 2) + cardSpace * (armsList.length / 2)}
        >
          <StyledWrapper
            $animateFirst={animateFirst}
            $animateSecond={animateSecond}
            $animateFour={animateFour}
            $length={armsList.length}
          >
            {armsList.map((arm: any, index: number) => {
              return (
                <StyledCard
                  key={index.toString()}
                  style={{
                    zIndex: armsList.length - index,
                  }}
                  $animateSecond={animateSecond}
                  $animateFirst={animateFirst}
                  $animateFour={animateFour}
                >
                  <Link
                    href={arm.armLink[0].url}
                    target={arm.armLink[0].target}
                    onClick={() => activeArmsClick(arm.logoImage.altText, "זרועות פעילות")}
                  >
                    <StyledImageWrapp>
                      <StyledOverlay />
                      <ImageWithAlt
                        image={arm.image}
                        fill
                        style={{ borderRadius: "12px", objectFit: "cover" }}
                      />
                      <StyledInfoContainer>
                        <StyledLogoDiv>
                          <ImageWithAlt
                            image={arm.logoImage}
                            height={48}
                            width={130}
                            style={{
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                          />
                        </StyledLogoDiv>
                        <IconButton>
                          <LeftArrow />
                        </IconButton>
                      </StyledInfoContainer>
                    </StyledImageWrapp>
                  </Link>
                </StyledCard>
              )
            })}
          </StyledWrapper>
        </StyledMainContainer>
      </Container>
    </StyledContainer>
  )
}

export default AnimateCardsMobile

"use client"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import { Container, useInViewConfig, useIsDesktop, useTransitionUpStyles } from "ui"
import SectionTitle from "ui/SectionTitle"
import { delayTime, styleObjectToCss } from "ui/transitions/utils"
import { Culture, ProjectFilters } from "utils"
import SliderComponent from "./SliderComponent"

const StyledHeadContainer = styled.div<{ cssString?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${(p) => css(p.cssString)}
`
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 60px;
  }
`

// const FiltersContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   border-radius: 16px;
//   padding: 24px;
//   background-color: ${props=>props.theme.colors.propBlue};
//   gap: 16px;
// `
type Props = {
  title: string
  slides: {
    name: any
    id: number | null | undefined

    url: string | null | undefined
    properties: {
      heroBannerDesktop: string
      heroBannerMobile: string
      projectTitle: any
      location: string
    }
  }[]
  culture: Culture
  filters: ProjectFilters
  locale: string | undefined
  homepageUrl?: string
  page: string
  filterTitle?: string
  allProjects?: any
  defaultPlaying?: boolean
}

// const withoutFilter = [
//   "energy",
//   "international",
//   "construction",
//   "industries",
//   "concessions",
//   "engineering",
//   "drachim",
//   "base",
//   "melet",
//   "ashcrete",
// ]

function ProjectsSlider({ title, slides, culture, defaultPlaying }: Props) {
  // const [state, setState] = useState<StateType>({
  //   designations: initializeFilterOptions(filters.designations),
  //   subsidiaries: initializeFilterOptions(filters.subsidiaries),
  //   statuses: initializeFilterOptions(filters.statuses),
  //   countries: initializeFilterOptions(filters.countries),
  //   cities: initializeFilterOptions(filters.cities),
  // })

  const defaultTitle = "יוצרים מציאות חדשה"
  const isDesktop = useIsDesktop()

  const { ref: firstRef, inView } = useInView(useInViewConfig)
  const titleTransitionStyles = useTransitionUpStyles(inView)
  const sliderTransitionStyles = useTransitionUpStyles(inView, delayTime)

  return (
    <>
      {slides && slides.length > 0 && (
        <Container
          style={{
            paddingTop: isDesktop ? "60px" : "40px",
          }}
        >
          <StyledContainer>
            <div ref={firstRef}>
              <StyledHeadContainer cssString={styleObjectToCss(titleTransitionStyles)}>
                <SectionTitle title={title || defaultTitle} component="h2" />
              </StyledHeadContainer>
              <SliderComponent
                defaultPlaying={defaultPlaying}
                slides={slides}
                title={title || defaultTitle}
                culture={culture}
                cssString={styleObjectToCss(sliderTransitionStyles)}
              />
            </div>
            {/* {!withoutFilter.includes(page) && (
              <FiltersContainer>
                <NewText
                  desktopFontWeight={600}
                  component={"h3"}
                  desktopFontSize={24}
                  smallDesktopLineHeight={"22px"}
                  color={sharedColors.white}
                  mobileFontSize={20}
                  mobileLineHeight={"24px"}
                >
                  {filterTitle}
                </NewText>
                <Filters
                  filters={filters}
                  setState={setState}
                  state={state}
                  homepage={true}
                  homepageUrl={homepageUrl}
                  locale={locale}
                  element="חיפוש פרויקטים של קבוצת אשטרום"
                  page={page}
                  projects={allProjects}
                />
              </FiltersContainer>
            )} */}
          </StyledContainer>
        </Container>
      )}
    </>
  )
}
export default ProjectsSlider
